// 项目部署的时候可以自动获取该服务器的真是ip+端口
const dirServicePath = window.serverConfig.dirServicePath;
// 服务数据
const serverUrl = {
    // **************************** 登录 **************************** //
    login: (category) => {
        return {
            loginPort: `${dirServicePath}/oc-admin/login`
        }[category]
    },
    // **************************** 退出 **************************** //
    logout: (category) => {
        return {
            logoutPort: `${dirServicePath}/oc-admin/logout`
        }[category]
    },
    // **************************** 修改密码 **************************** //
    modifyPwd: (category) => {
        return {
            modifyPwdApi: `${dirServicePath}/oc-admin/user/changePwd`
        }[category]
    },
    // **************************** 站内消息 **************************** //
    globalNotice: (category) => {
        return {
            globalNoticeApi: `${dirServicePath}/oc-admin/notice`
        }[category]
    },
    // **************************** 首页预览 **************************** //
    firstView: (category) => {
        return {
            queryOverView: `${dirServicePath}/oc-admin/statistic/queryOverView`,
            queryChart: `${dirServicePath}/oc-admin/statistic/queryChart`
        }[category]
    },
    // **************************** 地图级联选择器 **************************** //
    dt: (category) => {
        return {
            // 省份
            paasDictType : `${dirServicePath}/oc-admin/v1/paas-dict-type/list`,
            // 市
            paasDictData : `${dirServicePath}/oc-admin/v1/paas-dict-data/list`,
        }[category]
    },
    // **************************** 站所树接口 **************************** //
    tree: (category) => {
        return {
            // 分组树
            groupTree : `${dirServicePath}/oc-admin/station/group-tree`,
            // 树搜索
            commonTree: `${dirServicePath}/oc-admin/station/tree`,
        }[category]
    },
    // **************************** 基础管理 **************************** //
    jcgl: (category, ...args) => {
        const id = args[0];
        return {
            // 新增轨道
            addStation: `${dirServicePath}/oc-admin/station/add`,
            // 站所管理 导出
            stationExport: `${dirServicePath}/oc-admin/station/download`,
            // 获取站点列表
            requestStationList: `${dirServicePath}/oc-admin/station/query/page`,
            // 获取全部站点列表
            requestAllStationList: `${dirServicePath}/oc-admin/station/query/list`,
            // 删除站所
            deleteStation: `${dirServicePath}/oc-admin/station/del/${id}`,
            // 编辑站所
            editStation: `${dirServicePath}/oc-admin/station/updated`,
            // 编辑站所隐藏状态
            updateShowStatus: `${dirServicePath}/oc-admin/station/update-show-status`,
            // 返回一个站点的信息
            getStationDetailSelect: `${dirServicePath}/oc-admin/station/stationDetails`,
            // 更新站点信息
            updateStation: `${dirServicePath}/oc-admin/station/changeInfo`,
            // 获取车位列表
            requestStallList: `${dirServicePath}/oc-admin/admin/station-parking/queryAll`,
            // 获取电费缴费列表
            getElecPayRecordList: `${dirServicePath}/oc-admin/admin/paymentRecord/query`,
            // 增加电费缴费凭据
            addPayRecord: `${dirServicePath}/oc-admin/admin/paymentRecord/add`,
            // 删除电费缴费记录
            deletePayRecord: `${dirServicePath}/oc-admin/admin/paymentRecord/delete/${id}`,
            // App全局配置
            getAppConfig: `${dirServicePath}/oc-admin/app-config`,
            // App 预约修改配置
            modifyAppUpdate: `${dirServicePath}/oc-admin/app-config/update`,
            // 运营主体配置/查询发票主体
            getDrawerPageList: `${dirServicePath}/oc-admin/admin/drawer/page-list`,
            // 运营主体配置/添加发票主体
            getAddDrawer: `${dirServicePath}/oc-admin/admin/drawer/add-drawer`,
            // 运营主体配置/根据id删除主体
            getDrawerDel: `${dirServicePath}/oc-admin/admin/drawer/del-drawer/${id}`,
            // 运营主体配置/查询发票主体关联的站点
            getPageRelation: `${dirServicePath}/oc-admin/admin/drawer/page-relation`,
            // 运营主体配置/配置小区/查看一个主体的站点关系
            getDrawerStationQuery: `${dirServicePath}/oc-admin/admin/drawer/query-relation`,
            // 运营主体配置/配置小区/添加关系
            getAddRelation: `${dirServicePath}/oc-admin/admin/drawer/add-relation`,
            // 运营主体配置/商户账户配置/银行列表
            getBankList: `${dirServicePath}/oc-admin/merchant/bankList`,
            // 运营主体配置/商户账户配置/站点商户列表
            corpMemberList: `${dirServicePath}/oc-admin/admin/corp-member/query`,
            // 运营主体配置/商户账户配置/站点商户列表
            getMerchantList: `${dirServicePath}/oc-admin/merchant/list`,
            // 运营主体配置/商户账户配置/商户详情
            getMerchantDetail: `${dirServicePath}/oc-admin/merchant/detail`,
            // 运营主体配置/商户账户配置/修改弹框/上传证书
            modifyUploadCert: `${dirServicePath}/oc-admin/merchant/uploadCert`,
            // 运营主体配置/商户账户配置/站点商户信息修改
            modifyMerchant: `${dirServicePath}/oc-admin/merchant/modify`,
            // 运营主体配置/商户账户配置/通过id锁定商户
            freeze: `${dirServicePath}/oc-admin/admin/corp-member/freeze/${id}`,
            // 运营主体配置/商户账户配置/通过id解除锁定商户
            unfreeze: `${dirServicePath}/oc-admin/admin/corp-member/unfreeze/${id}`,
            // 运营主体配置/商户账户配置/通过id查询商户
            corpMember: `${dirServicePath}/oc-admin/admin/corp-member/${id}`,
            // 运营主体配置/账户账户配置/通过id查询账户
            corpAccount: `${dirServicePath}/oc-admin/admin/corp-account/${id}`,
            // 运营主体配置/账户账户配置/新增
            addCorpMember: `${dirServicePath}/oc-admin/admin/corp-member`,
            // 运营主体配置/账户账户配置/修改账户
            editCorpAccount: `${dirServicePath}/oc-admin/admin/corp-account`,
            // 运营主体配置/汇付商户配置/提现配置/查询提现配置
            queryWithdrawConfig:  `${dirServicePath}/oc-admin/admin/corp-account/query-cycle`,
            // 运营主体配置/汇付商户配置/提现配置/修改提现配置
            updateWithdrawConfig: `${dirServicePath}/oc-admin/admin/corp-account/update-cycle`,
            // 运营主体配置/账户账户配置/修改商户
            editCorpMember: `${dirServicePath}/oc-admin/admin/corp-member`,
            // 运营主体配置/商户账户配置/检验操作
            getMerchantModify: `${dirServicePath}/oc-admin/merchant/check`,
            // 运营主体配置/商户账户配置/绑定站点列表
            stationList: `${dirServicePath}/oc-admin/merchant/stationList`,
            // 运营主体配置/商户账户配置/绑定站点
            merchantBind: `${dirServicePath}/oc-admin/merchant/bind`,
            // 运营主体配置/开票主体/删除
            delRelation: `${dirServicePath}/oc-admin/admin/drawer/del-relation`,
            // 获取站端ip
            getIp: `${dirServicePath}/oc-admin/station/getUuid`,
            // 心跳检测
            heartbeatDetection : `${dirServicePath}/oc-admin/station/heartbeat-detection`,
            // 取消心跳检测
            heartbeatDetectionCancel : `${dirServicePath}/oc-admin/station/cancel/heartbeat-detection`,
            // 车牌分页查询
            licenseQueryPages : `${dirServicePath}/oc-admin/station/license/query/pages`,
            // 删除车牌
            licenseDelete : `${dirServicePath}/oc-admin/station/license/delete`,
            // 新增车牌
            licenseCreate : `${dirServicePath}/oc-admin/station/license/create`,
            // 修改车牌
            licenseUpdate : `${dirServicePath}/oc-admin/station/license/update`,
            // 车牌状态
            licenseStatus : `${dirServicePath}/oc-admin/station/license/status`,
            // 提现记录分页查询
            getCorpDrawCash : `${dirServicePath}/oc-admin/admin/corp-draw-cash/query`,
            // 取现
            drawCash : `${dirServicePath}/oc-admin/admin/corp-draw-cash/draw-cash`,
            // 通过商户id查询可用余额
            corpMemberId : `${dirServicePath}/oc-admin/admin/corp-draw-cash/corp_member_id/${id}`,
            // 推流控制操作
            cameraControl : `${dirServicePath}/oc-admin/admin/device/camera-control`,
            // 相机播放心跳
            cameraHeartbeat : `${dirServicePath}/oc-admin/admin/device/camera-heartbeat`,
            // 二轮车站所详情编辑使用
            ebikeInfoUpdate : `${dirServicePath}/oc-admin/station/ebike-info-update`,
            // 根据站点查看设备总览数据
            queryDeviceOverview : `${dirServicePath}/os-device/ebike/query-device-overview`,
            // 根据站点查看设备总览数据
            queryDeviceOverviewList : `${dirServicePath}/os-device/ebike/query-device-overview-list`,
            // 插座列表
            queryDevicePointList : `${dirServicePath}/os-device/ebike/query-device-point-list`,
            // 根据充电接口号id查找枪状态
            queryOnlineChargingPortById : `${dirServicePath}/os-device/ebike/query-online-charging-port-by-id`,
            // 设备的实时状态
            queryStatus : `${dirServicePath}/os-device/ebike/status/query`,
            // 站所分组列表查询
            stationGroupList : `${dirServicePath}/oc-admin/station-group/query/list`,
            // 站所分组列表新增
            createStationGroup : `${dirServicePath}/oc-admin/station-group/create`,
            // 设置站所群组
            setGroup : `${dirServicePath}/oc-admin/station-group-relation/set-group`,
            // 删除站所群组
            deleteGroup : `${dirServicePath}/oc-admin/station-group/delete`,
            // 修改站所群组
            updateGroup : `${dirServicePath}/oc-admin/station-group/update`,



        }[category];
    },
    // **************************** 设备信息 **************************** //
    equipment: (category, ...args) => {
        const id = args[0];
        return {
            // 获取站所列表
            getStationList: `${dirServicePath}/oc-admin/station/query/page`,
            // 获取轨道列表
            getTrackList: `${dirServicePath}/oc-admin/admin/device/track`,
            // 获取当前轨道下统计数据信息
            getStatisticsData: `${dirServicePath}/oc-admin/admin/device/count`,
            // 获取机器人表格信息
            getRobotData: `${dirServicePath}/oc-admin/robot/get/${id}`,
            // 获取充电桩表格信息
            getChargingListData: `${dirServicePath}/oc-admin/admin/device/charger-pile`,
            // 获取充电桩统计
            getChargerPileCount: `${dirServicePath}/oc-admin/admin/device/charger-pile-count`,
            // 获取轨道图形信息
            getTrackData: `${dirServicePath}/oc-admin/admin/device/socket`,
            // 异常设备信息
            getEquipmentData: `${dirServicePath}/oc-admin/admin/device/error/query`,
            // 查询一个机器人的电池信息
            getRobotCellOneData: `${dirServicePath}/oc-admin/admin/cell/cellOne`,
            // 重启机器人
            getRestartRobotData: `${dirServicePath}/oc-admin/admin/cell/restart`,
            // 查询充电桩详情
            chargingPileDetails: `${dirServicePath}/oc-admin/admin/device/chargingPileDetails`,
            // 修改充电桩状态
            chargingStatus: `${dirServicePath}/oc-admin/admin/device/chargingStatus`,
            // 刷新
            refreshStatus: `${dirServicePath}/oc-admin/admin/device/refresh_status`,
            // 远程控制发送手机短信
            sendSms: `${dirServicePath}/oc-admin/admin/device/send-sms`,
            // 远程控制手机短信验证
            phoneVerification: `${dirServicePath}/oc-admin/admin/device/phone-verification`,
            // 远程控制操作
            remoteControl: `${dirServicePath}/oc-admin/admin/device/remote-control`,
            // 远程控制是否需要验证
            verificationCompleted: `${dirServicePath}/oc-admin/admin/device/verification-completed`,
            // 查询模块
            module: `${dirServicePath}/oc-admin/upload-file/module`,
            // 查询模块
            logList: `${dirServicePath}/oc-admin/upload-file/query-log-list`,
            // 查询模块
            getLog: `${dirServicePath}/oc-admin/upload-file/get-log-file`,
            // 查询直流桩充电枪详情
            queryGunDetail: `${dirServicePath}/oc-admin/admin/device/chargingPileGunDetails`,
            // 充电枪历史订单统计数据
            queryGunHistoryDetail: `${dirServicePath}/oc-admin/admin/device/charge-guns/statistics`,
            // 根据充电桩编码获取在线充电枪
            getOnlineGunsByPileNo: `${dirServicePath}/oc-admin/charger/query-online-gun`,
            // 根据枪编号获取充电枪信息
            getGunInfoByGunNo: `${dirServicePath}/oc-admin/charger/query-connectorCode`,
            // 根据充电桩编码获取充电枪
            getGunsByPileNo: `${dirServicePath}/oc-admin/charger/query-connectorCodes`,
        }[category];
    },
    // **************************** 订单管理 充电订单 **************************** //
    rechargeOrder: (category, ...args) => {
        const id = args[0];
        return {
            getRechargeOrderData: `${dirServicePath}/oc-admin/admin/order/query`,
            // 订单详情查询
            getInfoDataList: `${dirServicePath}/oc-admin/admin/order/details`,
            changeModify: `${dirServicePath}/oc-admin/admin/order/modify`,
            // 订单导出
            export: `${dirServicePath}/oc-admin/admin/order/downloadOrder`,
            // 异常订单/预约订单 列表查询
            getAbnormalOrder: `${dirServicePath}/oc-admin/admin/order/query`,
            // 异常订单 订单详情查询
            getOrderDetails: `${dirServicePath}/oc-admin/admin/order/details`,
            // 异常订单 结束订单
            getStopOrder: `${dirServicePath}/oc-admin/admin/order/stopOrder`,
            // 异常订单 发起退款保存
            appeal: `${dirServicePath}/oc-admin/admin/order/appeal`,
            // 异常订单 账单查询
            refreshOrder: `${dirServicePath}/oc-admin/admin/order/refreshOrder`,
            // 订单管理/结算订单/获取充电中的缓存信息
            getChargingOrderCache: `${dirServicePath}/oc-admin/admin/order/charge-cache-info`,
            // 订单管理/结算订单/结束订单
            stopChargingOrder: `${dirServicePath}/oc-admin/admin/order/settlement`,
            // 订单管理/结算订单/确认更新账单
            updateOrderInfo: `${dirServicePath}/oc-admin/admin/order/update-info`,
        }[category];
    },
    // **************************** 用户管理 **************************** //
    yhgl: (category, ...args) => {
        // 轨道id
        const userId = args[0];
        return {
            // 获取用户列表
            requestUserList: `${dirServicePath}/oc-admin/admin/user-info/all`,
            // 设置名单黑白
            setListType: `${dirServicePath}/oc-admin/admin/user-info/removeStatus`,
            // 删除用户
            deleteUser: `${dirServicePath}/oc-admin/admin/user-info/del/${userId}`,
            // 导出
            export: `${dirServicePath}/oc-admin/admin/user-info/download`,
            // 置为测试用户
            setTestUser: `${dirServicePath}/oc-admin/admin/user-info/alterTest`,
            // 用户管理 更改分组
            setChangeGroup: `${dirServicePath}/oc-admin/admin/user-info/changeGroup`,
            // 用户管理 特享用户 列表查询
            getPrivilegeUsers: `${dirServicePath}/oc-admin/admin/user-info/getPrivilegeList`,
            // 用户管理 特享用户 配置
            modifyPrivilegeInfo: `${dirServicePath}/oc-admin/admin/user-info/privilege`,
            // 站所用户管理 用户管理 查询站所用户列表
            getStationUserList: `${dirServicePath}/oc-admin/admin/user-info/station/list`,
            //站所黑名单用户或者特享用户列表
            getSpecialUserList: `${dirServicePath}/oc-admin/admin/user-info/station/specialUserList`,
            // 站所用户管理 用户管理 特享用户 折扣配置 站点折扣率查询
            getQueryRate: `${dirServicePath}/oc-admin/admin/user-info/queryRel`,
            //  用户管理 黑名单用户 批量配置
            blackConfigList: `${dirServicePath}/oc-admin/admin/user-info/blackList`,
            // 用户管理 站所黑名单列表
            getStationBlackList: `${dirServicePath}/oc-admin/admin/user-info/station/blackList`,
            // 站所用户管理导出
            stationExport: `${dirServicePath}/oc-admin/admin/user-info/stationUserDownload`,
            // 用户管理 特享用户 删除站所
            deletePrivilgeStation: `${dirServicePath}/oc-admin/admin/user-info/privilege`,
            // 用户管理/微信特享用户导出
            getExclusiveDownload: `${dirServicePath}/oc-admin/admin/user-info/exclusiveDownload`,
            // 用户管理/微信黑名单用户导出
            getBlackDownload: `${dirServicePath}/oc-admin/admin/user-info/blackDownload`,
            // 用户管理/站点特享用户导出
            stationExclusiveDownload: `${dirServicePath}/oc-admin/admin/user-info/stationExclusiveDownload`,
            // 用户管理/站点黑名单用户导出
            stationBlackDownload: `${dirServicePath}/oc-admin/admin/user-info/stationBlackDownload`,
            // 用户管理/用户车牌编辑
            licence: `${dirServicePath}/oc-admin/admin/user-info/licence`,
            //用户管理/通过手机号号码查询用户
            searchUserByMobile: `${dirServicePath}/oc-admin/admin/user-info/station/search`,
            //用户管理/查询用户关联特殊站所
            special: `${dirServicePath}/oc-admin/admin/user-info/special`
        }[category];
    },
    /**
     * 运维管理
     * */
    ywgl: (category, ...args) => {
        const questionId = args[0];
        const id = args[0];
        const moduleId = args[0]
        const regularId = args[0]
        return {
            // 获取站点信息下拉列表
            getYwglStationList: `${dirServicePath}/oc-admin/station/query/page`,
            // 获取列表
            getYwglDataList: `${dirServicePath}/oc-admin/v1/issue-report/query`,
            // 故障处理
            getYwglIssueReport: `${dirServicePath}/oc-admin/v1/issue-report/fault`,
            // 故障处理/维保记录/列表查询
            getYwglCompletedList: `${dirServicePath}/oc-admin/fault/deal/completedList`,
            // 故障处理/维保记录/详情
            getYwglDetailInfo: `${dirServicePath}/oc-admin/fault/deal/detail`,
            // 故障处理：所有上报列表
            getAllFaultReportList: `${dirServicePath}/oc-admin/admin/faultReport/query`,
            // 故障处理：新增故障
            addNewFaultReport: `${dirServicePath}/oc-admin/admin/faultReport/add`,
            // 故障处理：待处理故障列表
            getYwglFaultDealList: `${dirServicePath}/oc-admin/fault/deal/list`,
            // 故障处理：催办故障处理
            postUrgeFault: `${dirServicePath}/oc-admin/fault/deal/urge`,
            // 故障处理：故障详情
            getFaultDetail: `${dirServicePath}/oc-admin/fault/deal/detail`,
            // 故障处理：更新处理状态
            updateFaultStatus: `${dirServicePath}/oc-admin/fault/deal/update`,
            // 故障处理：上传图片
            uploadtFaultImage: `${dirServicePath}/oc-admin/cos-file/putCos`,
            // 故障处理：建议反馈列表
            getFeedbackList: `${dirServicePath}/oc-admin/feedback/query`,
            // 导出设备异常-用户上报
            faultReportExport: `${dirServicePath}/oc-admin/admin/faultReport/export`,
            // 故障处理导出
            dealExportFault: `${dirServicePath}/oc-admin/fault/deal/exportFault`,
            // 维保记录导出
            exportCompletedList: `${dirServicePath}/oc-admin/fault/deal/exportCompletedList`,
            // 建议反馈/用户建议导出
            feedbackExport: `${dirServicePath}/oc-admin/feedback/export`,
            // 运维管理/二维码导出
            qrcodeExport: `${dirServicePath}/oc-admin/gen-qrcode/export`,
            // 运维管理 / 查询内网穿透 列表
            queryFrpList: `${dirServicePath}/oc-admin/frp/query`,
            // 运维管理 / 内网穿透 模块 新增
            addFrpModule: `${dirServicePath}/oc-admin/frp/add`,
            // 运维管理 / 内网穿透 模块 全部停止
            stopAllFrpModule: `${dirServicePath}/oc-admin/frp/stop-all`,
            // 运维管理 / 内网穿透 模块 启用
            startFrpModule: `${dirServicePath}/oc-admin/frp/start-module`,
            // 运维管理 / 内网穿透 根据模块ID查询
            queryFrpModuleById: `${dirServicePath}/oc-admin/frp/module-detail`,
            // 运维管理 / 内网穿透 模块 更新
            updateFrpModule: `${dirServicePath}/oc-admin/frp/update`,
            // 运维管理 / 内网穿透 模块 删除
            deleteFrpModule: `${dirServicePath}/oc-admin/frp/del/${moduleId}`,
            // 运维管理 / 内网穿透 模块 重新分配端口
            freshFrpModulePort: `${dirServicePath}/oc-admin/frp/module-port`,
            // 运维管理 / 内网穿透 模块 启用
            enableFrpModule: `${dirServicePath}/oc-admin/frp/start-module`,

            // 运维管理 / 对接管理 新增对接方
            addJointPartner: `${dirServicePath}/oc-admin/regular/add`,
            // 运维管理 / 对接管理 新增对接方
            getJointPartnerList: `${dirServicePath}/oc-admin/regular/query`,
            // 运维管理 / 对接管理 新增对接方
            updateJointPartner: `${dirServicePath}/oc-admin/regular/update`,
            // 运维管理 / 对接管理 查询对接方
            queryJointPartner: `${dirServicePath}/oc-admin/regular/regular-detail`,
            // 运维管理 / 对接管理 删除对接方
            deleteJointPartner: `${dirServicePath}/oc-admin/regular/del/${regularId}`,
            // 运维管理 / 对接管理 获取密钥信息
            queryJointKeyInfo: `${dirServicePath}/oc-admin/regular/get-secret`,
            // 运维管理 / 对接管理 获取随机密钥
            getSecretKeyAuto: `${dirServicePath}/oc-admin/regular/auto-secret`,
            // 运维管理 / 对接管理 设置密钥信息
            setJointKeyInfo: `${dirServicePath}/oc-admin/regular/set-secret`,


            // 问题汇总 查询所有问题
            getYwglAllQuestion: `${dirServicePath}/oc-admin/admin/questions/query`,
            // 问题汇总 新增问题
            getYwglAddQuestion: `${dirServicePath}/oc-admin/admin/questions/add`,
            // 问题汇总 删除问题
            getYwglDelQuestion: `${dirServicePath}/oc-admin/admin/questions/delete/${questionId}`,
            // 问题汇总 修改问题
            getYwglModityQuestion: `${dirServicePath}/oc-admin/admin/questions/modify`,
            // 附件下载
            getYwglZipList: `${dirServicePath}/oc-admin/v1/issue-report/zip`,
            // 客服电话/人工客服分页查询
            getArtificialPhoneList: `${dirServicePath}/oc-admin/admin/customer/query`,
            // 客服电话/新增人工客服
            getNewAddPhone: `${dirServicePath}/oc-admin/admin/customer/add`,
            // 客服电话/删除客服电话
            delArtificialInfo: `${dirServicePath}/oc-admin/admin/customer/del/${id}`,
            // 客服电话/所在城市
            getCitys: `${dirServicePath}/oc-admin/station/queryCityAndCode`,
            // 用户隐私协议/用户协议/列表查询
            getUserAgreement: `${dirServicePath}/oc-admin/admin/userAgreement/query`,
            // 用户隐私协议/用户协议/删除接口
            getUserAgreementDel: `${dirServicePath}/oc-admin/admin/userAgreement/del/${id}`,
            // 用户隐私协议/用户协议/上传文件
            getUserAgreementUpload: `${dirServicePath}/oc-admin/cos-file/putCos`,
            // 用户隐私协议/用户协议/添加文件接口
            getUserAgreementAdd: `${dirServicePath}/oc-admin/admin/userAgreement/add`,
            // 用户隐私协议/用户协议/下载
            getUserAgreementDown: `${dirServicePath}/oc-admin/cos-file/newDown`,
            // 系统公告/升级公告/公告查询
            getUpgradeNoticeSearch: `${dirServicePath}/oc-admin/upgrade/notice/list`,
            // 系统公告/升级公告/公告修改
            getUpgradeNoticEdit: `${dirServicePath}/oc-admin/upgrade/notice/edit`,
            // 系统公告/升级公告/批量启用
            batchEnableNotice: `${dirServicePath}/oc-admin/upgrade/notice/batch-upgrade`,
            // 设备使用记录表格
            recordList: `${dirServicePath}/oc-admin/admin/device/record/list`,
            // 设备使用详情
            recordDetail: `${dirServicePath}/oc-admin/admin/device/record/detail`,
            // 查询选择机器人的实时信息
            getQueryRealtimeStatus: `${dirServicePath}/oc-admin/admin/device/queryRealtimeStatus`,
            // 设备一览统计
            getQueryCount: `${dirServicePath}/oc-admin/admin/device/count`,
            // 完成保养
            finishMaintenance: `${dirServicePath}/oc-admin/admin/device/finishMaintenance`,
            // 获取所有保养数据
            listOfEquipment: `${dirServicePath}/oc-admin/admin/device/listOfEquipment/list`,
            // 充电桩订单数量
            orderCount: `${dirServicePath}/oc-admin/admin/device/orderCount`,
            // 充电桩订单量
            downloadOrder: `${dirServicePath}/oc-admin/admin/device/downloadOrder`,
            // 维保-信息列表查询
            maintenanceRecordList: `${dirServicePath}/oc-admin/admin/device/maintenanceRecord/list`,
            // 维保-去保养
            doMaintenance: `${dirServicePath}/oc-admin/admin/device/maintenanceRecord/doMaintenance`,
            // 查询云站所城市树状图
            cloudCityTree: `${dirServicePath}/oc-admin/station/cloud-city-tree`,
            // 云站端查询
            cloudStation: `${dirServicePath}/oc-admin/station/cloud-station`,
            // 电缆分页查询
            queryCablePages: `${dirServicePath}/oc-admin/cable/query/pages`,
            // 新增电缆
            createCable: `${dirServicePath}/oc-admin/cable/create`,
            // 修改电缆
            updateCable: `${dirServicePath}/oc-admin/cable/update`,
            // 删除电缆
            deleteCable: `${dirServicePath}/oc-admin/cable/delete`,
            // 电缆下拉
            queryCableList: `${dirServicePath}/oc-admin/cable/query/list`,
            // 获取充电桩表格
            queryChargerPages: `${dirServicePath}/oc-admin/charger/query/pages`,
            // 删除充电桩
            deleteCharger: `${dirServicePath}/oc-admin/charger/delete`,
            // 充电桩所有下拉数据
            chargerConfigure: `${dirServicePath}/oc-admin/charger/configure`,
            // 新增充电桩
            createCharger: `${dirServicePath}/oc-admin/charger/create`,
            // 获取ip
            chargerIp: `${dirServicePath}/oc-admin/charger/ip`,
            // 修改分支箱
            updateCharger: `${dirServicePath}/oc-admin/charger/update`,
            // 站所配置列表查询
            queryConfigure: `${dirServicePath}/oc-admin/station-configure/query`,
            // 修改站所配置
            updateConfigure: `${dirServicePath}/oc-admin/station-configure/update`,
             // 恢复站所配置
            deleteConfigure: `${dirServicePath}/oc-admin/station-configure/delete`,
            // 导入充电桩批量模板
            importCharger: `${dirServicePath}/oc-admin/charger/import`,
            // 确认生成充电桩
            doMakeCharger: `${dirServicePath}/oc-admin/charger/do-make-charger`,
            // 更新预生成充电桩
            updateBatchCharger: `${dirServicePath}/oc-admin/charger/update-batch-charger`,
            // 分页查询预生成充电桩
            queryBatchCharger: `${dirServicePath}/oc-admin/charger/query-batch-charger`,
            // 删除预生成充电桩
            deleteBatchCharger: `${dirServicePath}/oc-admin/charger/delete-batch-charger`,
            // 下载充电桩批量导入模板
            downloadTemplate: `${dirServicePath}/oc-admin/charger/download-template`,
            // 查询在线充电桩
            queryOnlineCharger: `${dirServicePath}/oc-admin/charger/query-online-charger`,
            // 获取sim卡详细信息
            querySimCardInfo: `${dirServicePath}/oc-admin/admin/sim-card/query-sim-card-info`,
            // 获取流量集合
            queryTrafficList: `${dirServicePath}/oc-admin/admin/sim-card/query-traffic-list`,
            // 运维管理-->运维平台-->查询对接监控方
            queryRegular: `${dirServicePath}/oc-admin/regular/config/query-regular`,
            // 运维管理-->运维平台-->查询对接运营方
            queryOperator: `${dirServicePath}/oc-admin/regular/config/query-operator`,
            // 运维管理-->运维平台-->新增站所监控方
            regularAdd: `${dirServicePath}/oc-admin/regular/config/regular-add`,
            // 运维管理-->运维平台-->新增站所运营方
            operatorAdd: `${dirServicePath}/oc-admin/regular/config/operator-add`,
            // 运维管理-->运维平台-->对接方列表-选择用
            regularList: `${dirServicePath}/oc-admin/regular/config/regular-list`,
            // 删除对监控信息
            regularDel: `${dirServicePath}/oc-admin/regular/config/regular-del/${id}`,
            // 删除对运营信息
            operatorDel: `${dirServicePath}/oc-admin/regular/config/operator-del/${id}`,

            // 运维管理-->功率调度管理-->功率单元管理-地区树
            powerUnitArea: `${dirServicePath}/os-device/power-unit/area`,
            // 运维管理-->功率调度管理-->功率单元管理-档位和规格
            configure: `${dirServicePath}/os-device/power-unit/configure`,
            // 运维管理-->功率调度管理-->功率单元管理-获取数据
            powerUnitQuery: `${dirServicePath}/oc-admin/power-unit/query/page`,
            // 运维管理-->功率调度管理-->功率单元管理-新增
            powerUnitCreate: `${dirServicePath}/os-device/power-unit/create`,
            // 运维管理-->功率调度管理-->功率单元管理-编辑
            powerUnitUpdate: `${dirServicePath}/os-device/power-unit/update`,
            // 运维管理-->功率调度管理-->功率单元管理-绑定
            cableRelationBind: `${dirServicePath}/os-device/cable-relation/bind`,
            // 运维管理-->功率调度管理-->功率单元管理-解绑支持批量
            cableRelationUnbind: `${dirServicePath}/os-device/cable-relation/unbind`,
            // 运维管理-->功率调度管理-->功率单元管理-规格
            byParentCode: `${dirServicePath}/oc-admin/v1/paas-dict-data/list/by-parent-code`,
            // 运维管理-->功率调度管理-->功率单元管理-获取电缆下已绑定站所列表
            queryBindCable: `${dirServicePath}/oc-admin/cable-bind/cable/query`,
            // 运维管理-->功率调度管理-->功率单元管理-获取电缆下已绑定站所列表
            queryStationBindCable: `${dirServicePath}/oc-admin/cable-bind/station/query`,
            // 运维管理-->功率调度管理-->功率单元管理-删除
            powerUnitDelete: `${dirServicePath}/os-device/power-unit/delete`,
            // 运维管理-->功率调度管理-->功率单元下已绑定站所列表
            queryPowerUnit: `${dirServicePath}/oc-admin/cable-bind/power-unit/query`,
            // 运维管理-->功率调度管理-->实时数据台账
            realTimeData: `${dirServicePath}/oc-admin/power-unit/query/page/real-time-data`,

            // 运维管理-->设备管理-->车位台账列表
            parkingList: `${dirServicePath}/oc-admin/parking/list`,
            // 运维管理-->设备管理-->车位新增
            addParking: `${dirServicePath}/oc-admin/parking/add`,
            // 运维管理-->设备管理-->车位新增-批量
            batchAddParking: `${dirServicePath}/oc-admin/parking/batch-add`,
            // 运维管理-->设备管理-->车位修改
            updateParking: `${dirServicePath}/oc-admin/parking/update`,
            // 运维管理-->设备管理-->车位删除-批量
            batchDeleteParking: `${dirServicePath}/oc-admin/parking/batch-delete`,
            // 运维管理-->设备管理-->充电枪列表按站所id查询
            chargerConnectorList: `${dirServicePath}/oc-admin/charger/connector/list/${id}`,
            // 运维管理-->设备管理-->绑定充电枪-批量
            bindConnectors: `${dirServicePath}/oc-admin/parking/bind-connectors`,
            // 运维管理-->设备管理-->绑定地锁-批量
            bindGroundlock: `${dirServicePath}/oc-admin/parking/bind-ground-lock`,
            // 运维管理-->设备管理-->绑定车位
            bindParking: `${dirServicePath}/oc-admin/charger/bind-parking`,
            // 运维管理-->设备管理-->地锁台账列表
            queryGroundLockList: `${dirServicePath}/os-third-platform/ground-lock/list`,
            // 运维管理-->设备管理-->新增/更新地锁
            addOrUpdateGroundLock: `${dirServicePath}/os-third-platform/ground-lock/save-or-update`,
            // 运维管理-->设备管理-->删除地锁
            delGroundLockById: `${dirServicePath}/oc-admin/parking/ground-lock/del/${id}`,
            // 运维管理-->设备管理-->地锁控制
            groundLockControl: `${dirServicePath}/os-third-platform/ground-lock/lift`,

        }[category]
    },
    // **************************** 增值服务 **************************** //
    zzfw: (category, ...args) => {
        const stationId = args[0];
        const ammeterCode = args[0];
        const bindId = args[0];
        const oprId = args[0];
        const uuid = args[0];
        return {
            // 广告招租/广告投放/查询城市树状图
            getCityTree: `${dirServicePath}/oc-admin/station/cityTree`,
            // 广告招租/广告投放/查询一个广告示例
            getOneAdvertisement: `${dirServicePath}/oc-admin/advertisement/queryOne/${stationId}`,
            // 广告招租/广告投放/添加一个广告
            getAddAdvertisment: `${dirServicePath}/oc-admin/advertisement/add`,
            // 广告招租/广告投放/获取站所列表
            getAdvertisementList: `${dirServicePath}/oc-admin/advertisement/list`,
            // 售桩管理/购买申请/购买桩列表查询
            queryUserPileList: `${dirServicePath}/oc-admin/admin/applyPile/queryUserPileList`,
            // 售桩管理/购买申请/申请记录列表查询
            queryApply: `${dirServicePath}/oc-admin/admin/applyPile/queryApply`,
            // 售桩管理/购买申请/新增固定桩租赁-购买申请
            addApplyPile: `${dirServicePath}/oc-admin/admin/applyPile/addApplyPile`,
            // 售桩管理/购买申请/新增固定桩租赁-固定桩申请审核
            approve: `${dirServicePath}/oc-admin/admin/applyPile/approve`,
            // 售桩管理/购买申请/新增固定桩租赁-申请记录下载zip
            applyDownload: `${dirServicePath}/oc-admin/admin/applyPile/applyDownload`,
            // 售桩管理/购买申请/新增固定桩租赁-个人桩页面列表下载zip
            download: `${dirServicePath}/oc-admin/admin/applyPile/download`,
            // 售桩管理/购买申请/新增固定桩租赁-个人桩首页列表查询
            queryAll: `${dirServicePath}/oc-admin/admin/applyPile/queryAll`,
            // 售桩管理/购买申请/新增固定桩租赁-站所管理搜索所有微信用户
            search: `${dirServicePath}/oc-admin/admin/user-info/station/search`,
            // 售桩管理/购买申请/新增固定桩租赁-站所固定桩列表查询
            queryStationAllPile: `${dirServicePath}/oc-admin/admin/applyPile/queryStationAllPile`,
            // 售桩管理/购买申请/新增固定桩租赁-购买桩接口
            buyPile: `${dirServicePath}/oc-admin/admin/applyPile/buyPile`,
            // 售桩管理/购买申请/新增固定桩租赁-解除购买绑定关系
            removePartner: `${dirServicePath}/oc-admin/admin/applyPile/removePartner`,
            // 售桩管理/购买申请/新增固定桩租赁-查询关联使用人
            queryPartner: `${dirServicePath}/oc-admin/admin/applyPile/queryPartner`,
            // 电表管理/个人电表管理/查询电表列表
            queryAmmeterList: `${dirServicePath}/oc-admin/ammeter/list`,
            //电表管理/查询电表计费模型
            queryAmmeterModel: `${dirServicePath}/oc-admin/ammeter/price/query/${ammeterCode}`,
            //电表管理/查询电表绑定用户
            getBindUser: `${dirServicePath}/oc-admin/ammeter/bind-user/list/${ammeterCode}`,
            // 电表管理/删除绑定用户
            delBindUser:  `${dirServicePath}/oc-admin/ammeter/bind-user/del/${bindId}`,
            // 电表管理/用户绑定电表
            addBindUser: `${dirServicePath}/oc-admin/ammeter/bind-user/add`,
            // 电表管理/新增电表
            addAmmeter: `${dirServicePath}/oc-admin/ammeter/add`,
            // 电表管理/删除电表
            deleteAmmeter: `${dirServicePath}/oc-admin/ammeter/del/${ammeterCode}`,
            // 电表管理/更新电表
            updateAmmeter: `${dirServicePath}/oc-admin/ammeter/update`,
            //电表管理/通过电表编号查询详情信息
            queryDetailAmmeter: `${dirServicePath}/oc-admin/ammeter/detail/${ammeterCode}`,
            //查询电表异步操作结果
            queryAsyncAmmeterResult:`${dirServicePath}/oc-admin/ammeter/async/${oprId}`,
            // 查询复合异步返回结果 （电表启用、计费模型设置）
            queryAsyncComplex: `${dirServicePath}/oc-admin/ammeter/async/complex/${uuid}`,
            // 电表管理/操作电表
            operateAmmeter: `${dirServicePath}/oc-admin/ammeter/operator`,
            // 电表管理/抄表
            readAmmeter: `${dirServicePath}/oc-admin/ammeter/read`,
            // 电表管理/读取异步返回值
            readAsyncAmmeter: `${dirServicePath}/oc-admin/ammeter/read/${oprId}`,
            // 电表/设置计费模型
            setPriceAmmeter: `${dirServicePath}/oc-admin/ammeter/price/set`,
            // 电表/查询单个电表数据（缓存）
            queryStatusAmmeter: `${dirServicePath}/oc-admin/ammeter/query-status/${ammeterCode}`,
        }[category];
    },
    // **************************** 计费模型 **************************** //
    jfmx: (category, ...args) => {
        const stationId = args[0];
        return {
            // 获取电价信息
            getElectricityPrice: `${dirServicePath}/oc-admin/admin/charge-model/query`,
            // // 新增
            addEditedTariff: `${dirServicePath}/oc-admin/admin/charge-model/found`,
            // 时间段恢复默认设置
            updateDefaultSet: `${dirServicePath}/oc-admin/admin/charge-model/updated`,
            // 站桩费恢复默认设置
            updateStationDefault: `${dirServicePath}/oc-admin/admin/charge-model/updated`,
            // 分账模型/根据站点查询运营主体
            getOpSubjectByStationId: `${dirServicePath}/oc-admin/admin/drawer/query-drawer/${stationId}`,
            // 分账模型/查询所有运营主体
            // getAllOpSubjects: `${dirServicePath}/oc-admin/admin/drawer/list`,
            // getAllOpSubjects: `${dirServicePath}/oc-admin/admin/corp-member/query`,
            getAllOpSubjects: `${dirServicePath}/oc-admin/admin/corp-member/list`,
            // 分账模型/创建分账模型
            createAllotAccountsModel: `${dirServicePath}/oc-admin/admin/settlement-model/update/batch`,
            // 分账模型/查询分账模型
            getAllotAccountsModel: `${dirServicePath}/oc-admin/admin/settlement-model/query/stationId/${stationId}`,
            // 分账模型之分账比例配置
            createAllotAccountsRatio: `${dirServicePath}/oc-admin/corp-settle-model/update/batch`,
            // 分账模式之查询站所分账信息
            getAllotAccountsRatio:  `${dirServicePath}/oc-admin/corp-settle-model/list/query/${stationId}`,
            // 计费模型保存（二轮）
            addUpdate:  `${dirServicePath}/os-device/config/charge-model/add-update`,
            // 计费模型获取（二轮）
            queryChargeModel:  `${dirServicePath}/os-device/config/charge-model/query`,
        }[category];
    },
    // 审批管理
    spgl: (category, ...agrs) => {
        const id = agrs[0];
        const prtflId = agrs[0];
        return {
            // 审批管理 列表查询
            getApprovalList: `${dirServicePath}/oc-admin/appeal/list`,
            // 订单管理 订单查询
            getOrderIdList: `${dirServicePath}/oc-admin/admin/order/query`,
            // 审批管理 处理退款申请
            gethandleOrder: `${dirServicePath}/oc-admin/appeal/refund`,
            // 开票申请/列表查询
            getInvoicList: `${dirServicePath}/oc-admin/approval/list`,
            // 开票申请/订单详情
            getInvoicIdInfo: `${dirServicePath}/oc-admin/approval/details`,
            // 开票申请/订单详情/上传
            getUploadPutCos: `${dirServicePath}/oc-admin/cos-file/putCos`,
            // 开票申请/订单详情/修改
            getInvoiceIdDispose: `${dirServicePath}/oc-admin/approval/dispose`,

            // 开票申请/无感支付开票/查询可开票的订单
            getNoSensePayInvoices: `${dirServicePath}/os-pay/v1/unconscious-pay/invoice/list`,
            // 开票申请/无感支付开票/查询开票详情
            getNoSensePayDetail: `${dirServicePath}/os-pay/v1/unconscious-pay/invoice/detail/${prtflId}`,
            // 开票申请/无感支付开票/发票信息同步给建行
            syncNoSensePayInvoice: `${dirServicePath}/os-pay/v1/unconscious-pay/invoice/upload`,

            // 预约数申请/列表查询
            getAppointmentList: `${dirServicePath}/oc-admin/appeal/list`,
            // 预约数申请/处理预约申诉
            getAppointmentBooking: `${dirServicePath}/oc-admin/appeal/booking`,
            // 审批模块/开票申请/开票导出
            getApprovalExport: `${dirServicePath}/oc-admin/approval/export`,
            // 审批模块/退款申请/退款-预约数导出
            getAppealExport: `${dirServicePath}/oc-admin/appeal/export`,
            // 审批模块/退款重试
            refundRetry: `${dirServicePath}/oc-admin/appeal/refundRetry`,
            // 审批模块/退开票导出pdf
            exportPdf: `${dirServicePath}/oc-admin/approval/export-pdf/${id}`,
        }[category];
    },
    // **************************** 远程升级 **************************** //
    ycsj: (category, ...args) => {
        // 轨道id
        const id = args[0];
        return {
            // 获取站所列表
            requestStationList: `${dirServicePath}/oc-admin/station/query/page`,
            // 获取站点详情
            requestDetailsSite: `${dirServicePath}/oc-admin/upgrade/device`,
            // 获取包信息
            requestPackageInformation: `${dirServicePath}/oc-admin/upgrade/station-detail`,
            // 升级包
            upload: `${dirServicePath}/oc-admin/upgrade/upload`,
            // 执行升级
            execUpgrade: `${dirServicePath}/oc-admin/upgrade/execUpgrade`,
            // 升级记录
            requestrecordsTable: `${dirServicePath}/oc-admin/upgrade/records`,
            // 获取第二级
            querySubRecord: `${dirServicePath}/oc-admin/upgrade/querySubRecord`,
            // 查询升级包
            pkgPage: `${dirServicePath}/oc-admin/upgrade/pkgPage`,
            // 删除升级包
            pkgDel: `${dirServicePath}/oc-admin/upgrade/pkgDel/${id}`,
            // 充电桩列表
            piles: `${dirServicePath}/oc-admin/upgrade/charger/list`,
            // 执行批量升级
            execBatchUpgrade: `${dirServicePath}/oc-admin/upgrade/exec-batch-upgrade`,
            // 本地站端列表
            stationLocal: `${dirServicePath}/oc-admin/upgrade/station-tree`,
            // 查询充电桩信息
            cloudChargerPile: `${dirServicePath}/oc-admin/upgrade/charger/page`,
            // 拿任务找记录
            taskRecords: `${dirServicePath}/oc-admin/upgrade/task-records`,
            // 云站端升级
            execCloudupgrade: `${dirServicePath}/oc-admin/upgrade/exec-cloud-upgrade`,
            // 整体任务记录
            taskSummary: `${dirServicePath}/oc-admin/upgrade/task/summary`,
            // 重试整体任务
            taskRetry: `${dirServicePath}/oc-admin/upgrade/task/retry`,
            // 重试整体任务
            existedVersion: `${dirServicePath}/oc-admin/upgrade/existed-version`,
            // 重试整体任务
            cloudChargerStatusLabel: `${dirServicePath}/oc-admin/upgrade/cloud-charger-status-label`
        }[category];
    },
    // **************************** 初始化页面获取权限 **************************** //
    getInfo: (category, ...args) => {
        return {
            // 获取权限树
            queryUserMenu: `${dirServicePath}/oc-admin/admin/menu/query-user-menu`,
            // 获取所有menu
            getAllMenu: `${dirServicePath}/oc-admin/admin/menu/all`
        }[category];
    },
    // **************************** 适配车型管理 **************************** //
    spcxgl: (category, ...args) => {
        // 品牌id
        const brandId = args[0];
        const carModelId = args[0];
        return {
            // 获取车的品牌下拉
            queryByInitials: `${dirServicePath}/oc-admin/admin/carBrand/queryByInitials`,
            // 获取车的品牌表格数据
            queryByInitialsPage: `${dirServicePath}/oc-admin/admin/carBrand/queryPage`,
            // 删除品牌
            deleteCarBrand: `${dirServicePath}/oc-admin/admin/carBrand/delId/${brandId}`,
            // 获取车型表格数据
            queryCarModelPage: `${dirServicePath}/oc-admin/admin/carModel/show`,
            // 删除车型
            deleteCarModel: `${dirServicePath}/oc-admin/admin/carModel/delete/${carModelId}`,
            // 添加车品牌
            addCarBrand: `${dirServicePath}/oc-admin/admin/carBrand/addBrand`,
            // 编辑车品牌
            editCarBrand: `${dirServicePath}/oc-admin/admin/carBrand/put`,
            // 添加车型号
            addCarModel: `${dirServicePath}/oc-admin/admin/carModel/add`,
            // 编辑车车型
            editCarModel: `${dirServicePath}/oc-admin/admin/carModel/modify`,
        }[category];
    },
    // **************************** 系统管理 **************************** //
    xtgl: (category, ...args) => {
        const stationId = args[0];
        const userId = args[0];
        const id = args[0]
        return {
            // 系统管理/登陆用户管理/站点详情查询
            userStationManager: `${dirServicePath}/oc-admin/station/query/page`,
            // 系统管理/登录用户管理/分页查询
            userTableListPage: `${dirServicePath}/oc-admin/user/listPage`,
            // 系统管理/登录用户管理/新增/角色查询
            roleSelcetListAll: `${dirServicePath}/oc-admin/v1/admin-role/getAllNotPaging`,
            // 系统管理/登录用户管理/新增后台用户
            userNewAdd: `${dirServicePath}/oc-admin/user/add`,
            // 系统管理/登录用户/修改/修改信息
            userModifyInfo: `${dirServicePath}/oc-admin/user/alter`,
            // 系统管理/登录用户/批量修改
            batchModifyUser: `${dirServicePath}/oc-admin/user/batch`,
            // 系统管理/登录用户/重置密码
            resetPassword: `${dirServicePath}/oc-admin/user/pwd`,
            // 系统管理/登录用户/删除用户
            deleteUserInfo: `${dirServicePath}/oc-admin/user/del/${userId}`,
            // 系统管理/登录用户/查询字典树
            queryDictByUser: `${dirServicePath}/oc-admin/v1/paas-dict-data/tree`,
            // 系统管理/系统日志查询/系统操作日志
            queryOptlogTable: `${dirServicePath}/oc-admin/admin/optlog/query`,
            // 系统管理/系统管理/业务字典/业务字典/获取字典树
            queryDictTreeList: `${dirServicePath}/oc-admin/v1/paas-dict-data/list`,
            // 系统管理/系统管理/业务字典/业务字典/新增字典数据
            addDictData: `${dirServicePath}/oc-admin/v1/paas-dict-data`,
            // 系统管理/系统管理/业务字典/业务字典/删除字典数据
            delDictData: `${dirServicePath}/oc-admin/v1/paas-dict-data/${id}`,
            // 系统管理/系统管理/业务字典/业务字典/修改字典数据
            updateDictData: `${dirServicePath}/oc-admin/v1/paas-dict-data`,
            // 系统管理/系统管理/业务字典/类型层级/层级树
            queryDictLayerTreeList: `${dirServicePath}/oc-admin/v1/paas-dict-type/tree`,
            // 系统管理/系统管理/业务字典/类型层级/新增类型
            addDictType: `${dirServicePath}/oc-admin/v1/paas-dict-type`,
            // 系统管理/系统管理/业务字典/类型层级/删除层级
            delDictType: `${dirServicePath}/oc-admin/v1/paas-dict-type/${id}`,
            // 系统管理/系统日志查询/导出
            export: `${dirServicePath}/oc-admin/admin/optlog/download`,
            // 系统管理/小程序管理/小程序主题配置 查询
            getAppletByName: `${dirServicePath}/oc-admin/app-config/getByName`,
            // 系统管理/小程序管理/小程序主题配置 修改
            modifyAppletByName: `${dirServicePath}/oc-admin/app-config/update`,
            // 系统管理/请求所有站所节点
            queryAllTreeStation: `${dirServicePath}/oc-admin/station/all-tree`,
        }[category];
    },
    // **************************** 权限管理 **************************** //
    roleManagement: (category, ...args) => {
        // 角色id
        const roleId = args[0];
        return {
            // 角色管理表格
            queryRoleTable: `${dirServicePath}/oc-admin/v1/admin-role/query`,
            // 删除角色
            deleteRole: `${dirServicePath}/oc-admin/v1/admin-role/del/${roleId}`,
            // 查询是否可以删除
            searchCanDeleteRole: `${dirServicePath}/oc-admin/v1/admin-role/queryOccupy`,
            // 添加角色
            addRole: `${dirServicePath}/oc-admin/v1/admin-role/add`,
            // 获取角色树
            getRoleTree: `${dirServicePath}/oc-admin/v1/admin-role/getAllNotPaging`,
            // 根据角色获取保存的对应关系树
            getRelation: `${dirServicePath}/oc-admin/admin/menu/query-role-menu`,
            // 保存的对应关系树
            saveRelation: `${dirServicePath}/oc-admin/v1/admin-role/addOrTake`,

        }[category];
    },
    // **************************** 运营统计 **************************** //
    operatingStatistics: (category, ...args) => {
        const redisKey = args[0]
        const stationId = args[0]
        const id = args[0]
        const orderId = args[0]
        return {
            // 获取统计电量
            getQuantity: `${dirServicePath}/oc-admin/admin/countanalys/quantity`,
            fee: `${dirServicePath}/oc-admin/admin/countanalys/fee`,
            // 获取站点树，层级查询
            getStationTree: `${dirServicePath}/oc-admin/station/queryProvince`,
            // 财务对账
            getAccountList: `${dirServicePath}/oc-admin/admin/countanalys/accountList`,
            // 详情
            getDetail: `${dirServicePath}/oc-admin/account/detail`,
            // 导出
            export: `${dirServicePath}/oc-admin/admin/countanalys/accountExport`,
            // 电表查询
            getAmmeter: `${dirServicePath}/oc-admin/admin/countanalys/ammeter`,
            // 运营平台新增查询电表列表接口
            ammeterNo: `${dirServicePath}/oc-admin/admin/countanalys/ammeterNo`,
            // 运营平台新增列表图数据查询接口
            listChart: `${dirServicePath}/oc-admin/admin/countanalys/listChart`,
            // 运营平台 用户分析数据查询接口
            userAnalysis: `${dirServicePath}/oc-admin/admin/countanalys/user`,
            // 运营平台 用户分析列表
            userAnalysisList: `${dirServicePath}/oc-admin/admin/countanalys/userList`,
            // 运营平台 用户分析汇总
            userAnalysisCount: `${dirServicePath}/oc-admin/admin/countanalys/userCount`,
            // 运营平台 用户分析导出
            userAnalysisExport: `${dirServicePath}/oc-admin/admin/countanalys/userExport`,
            // 运营平台 用户分析 用户行为分析 列表查询
            userBehaviorAll: `${dirServicePath}/oc-admin/admin/behavior/all`,
            // 运营平台 用户分析 用户行为分析 查询选择的用户
            getUserBehaviorInfo: `${dirServicePath}/oc-admin/admin/behavior/detail`,
            // 运营平台 用户分析 用户行为分析 查询选择用户的登录信息
            getBehaviorCellOne: `${dirServicePath}/oc-admin/admin/behavior/loginHistory`,
            // 运营平台 用户分析 用户行为分析 查询选择用户的用户行为
            getQueryBehavior: `${dirServicePath}/oc-admin/admin/behavior/userBehavior`,
            // 运营统计 / 财务清算 / 财务清算列表
            getSettleAccountsList: `${dirServicePath}/oc-admin/admin/settlement-request/query`,
            // 运营统计 / 财务清算 / 生成清算单头部
            createSettleAccountsHeader: `${dirServicePath}/oc-admin/admin/settlement-request/create`,
            // 运营统计 / 财务清算 / 生成清算单明细
            createSettleAccountsList: `${dirServicePath}/oc-admin/admin/settlement-detail/pre-queryPage`,
            // 运营统计 / 财务清算 / 确认清单
            confirmSettleAccounts: `${dirServicePath}/oc-admin/admin/settlement-request/confirm/${redisKey}`,
            // 运营统计 / 财务清算 / 查询清算头部信息
            getSettleAccountsHeader: `${dirServicePath}/oc-admin/admin/settlement-request/detail/${id}`,
            // 运营统计 / 财务清算 / 查询清算头部信息
            querySettleAccountsList: `${dirServicePath}/oc-admin/admin/settlement-detail/queryPage`,
            // 运营统计 / 财务清算 / 撤销清算单
            repealSettleAccounts: `${dirServicePath}/oc-admin/admin/settlement-request/delete/${id}`,
            // 运营统计 / 财务清算 / 确认清算完成
            confirmSettleAccountsFinish: `${dirServicePath}/oc-admin/admin/settlement-request/finish/${id}`,
            // 运营统计 / 财务清算 / 查询站所清算开始时间
            getSettleAccountStartTime: `${dirServicePath}/oc-admin/admin/settlement-request/start-date/${stationId}`,
            // 运营统计 / 财务清算 / 导出清算单
            exportSettleAccounts:`${dirServicePath}/oc-admin/admin/settlement-detail/export`,
            // 运营统计 / 财务清算 / 导出PDF
            exportSettleAccountsPDF:`${dirServicePath}/oc-admin/admin/settlement-request/export-pdf/${id}`,
            // 运营统计 / 财务清算 / 支付方式列表
            payMethod : `${dirServicePath}/oc-admin/merchant/pay-method`,
            // 运营统计 / 财务清算 / 商户号列表
            merchant: `${dirServicePath}/oc-admin/merchant`,
            // 运营统计 / 财务清算 / 对账统计
            account: `${dirServicePath}/oc-admin/admin/countanalys/account`,
            // 运营统计 / 财务清算 / 对账列表
            accountList: `${dirServicePath}/oc-admin/admin/countanalys/accountList`,
            // 运营统计 / 财务清算 / 对账列表导出
            billDetailsExport: `${dirServicePath}/oc-admin/admin/countanalys/billDetailsExport`,
            // 运营统计 / 财务分析 / 资金流水
            bill: `${dirServicePath}/oc-admin/account/bill`,
            // 运营统计 / 财务清算 / 资金流水导出
            billDownload: `${dirServicePath}/oc-admin/account/bill/download`,
            // 运营统计 / 对账统计 / 商户id查站所树
            corpMemberId: `${dirServicePath}/oc-admin/corp-settle-model/query/corp-member-id/${id}`,
            // 运营统计 / 对账统计 / 分账对账
            summary: `${dirServicePath}/oc-admin/account/dividend/summary`,
            // 运营统计 / 对账统计 / 分账明细查询
            dividendList: `${dirServicePath}/oc-admin/account/dividend/list`,
            // 运营统计 / 财务分析 / 分账统计 // 根据业务订单id查询分账详情
            getDividendDetailByOrderId: `${dirServicePath}/oc-admin/admin/corp-member-dividend-detail/query/${orderId}`,
            // 重新分账
            dividendRetry: `${dirServicePath}/oc-admin/admin/corp-member-dividend-detail/retry/${orderId}`,
            // 运营统计 / 对账统计 / 分账明细导出
            dividendListDownload: `${dirServicePath}/oc-admin/account/dividend/download`,
            // 未入账明细
            unMatchList:  `${dirServicePath}/oc-admin/admin/countanalys/un-match-list`,
        }[category];
    },

    // **************************** 云站管理（两轮） **************************** //
    yzgl: (category, ...args) => {
        return {
            // 充电桩列表
            queryEbikeList: `${dirServicePath}/os-device/ebike/list`,
            // 获取在线充电桩
            queryOnlineEbike: `${dirServicePath}/os-device/ebike/query-online-ebike`,
            // 根据在线电动自行车充电桩编码查找充电接口数据
            queryOnlineChargingPort: `${dirServicePath}/os-device/ebike/query-online-charging-port`,
            // 新增充电桩
            saveOrUpdate: `${dirServicePath}/os-device/ebike/save-or-update`,
            // 删除充电桩
            deleteEbike: `${dirServicePath}/os-device/ebike/delete`,
            // 重启充电桩
            restart: `${dirServicePath}/os-device/maintenance/restart`,
            // 更换ip地址
            setIp: `${dirServicePath}/os-device/maintenance/set-ip`,
            // 设置运行参数
            settingOne: `${dirServicePath}/os-device/maintenance/setting-one`,
            // 查询运行参数
            querySettingOne: `${dirServicePath}/os-device/maintenance/query-setting-one`,
            // 设置最大充电时长以及过载功率
            setPowerDuration: `${dirServicePath}/os-device/maintenance/set-power-duration`,
            // 请求最大充电时长以及过载功率
            queryPowerDuration: `${dirServicePath}/os-device/maintenance/query-power-duration`,

        }[category];
    },
};
export default serverUrl;
