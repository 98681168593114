// 公共类
import fdEventBus from "@/js/common/fdEventBus";

function FdGlobal() {
    /**
     * FdGlobal类，一个全局变量.
     * @constructo FdGlobalConstructor
     */
    this.version = '2022.6.1';
    this.author = 'yuanhui';
    this.name = 'FdGlobal';
    this.body = document.querySelector('body');
}

FdGlobal.prototype = {
    /**
     *
     * @name   consoleLogRequest
     * @time
     * @param showLog   {boolean}   是否输出日志
     * @param name     {string}  名称
     * @param method    {string}    请求方式  post,get,
     * @param url     {string}  请求的url
     * @param requestData   {object}  请求的数据
     * @description  为了统一管理request日志
     */

    /*eslint-enable*/ // eslint-disable-line
    consoleLogRequest(showLog, name, method, url, requestData) {
        if (showLog) {
            console.log(`[[模块名称]]==>${name}`);
            console.log('[[前台]]==>请求方式是');
            console.log(method);
            console.log('[[前台]]==>请求的url是');
            console.log(url);
            console.log('[[前台]]==>请求的数据是');
            console.log(requestData);
            console.log('\n');
            console.log('\n');
        }
    },

    /**
     * @name   consoleLogResponse
     * @time   2018-03-21  新增=> 为了统一管理日志
     * @param showLog    {boolean}  是否输出日志
     * @param name   {string} 名称
     * @param responseData {object}  后台返回的数据
     * @description  为了统一管理response日志
     */
    consoleLogResponse(showLog, name, responseData) {
        if (showLog) {
            console.log(`%c【模块名称】==>${name}`, 'color:#00cc66');
            console.log('%c【后台】==>返回的数据是', 'color:#00cc66');
            console.dir(responseData);
            console.log('\n');
            console.log('\n');
        }
    },

    /**
     * @name   requestError
     * @time   2018-03-21  新增=> 为了统一管理日志
     * @param data    {object}  报错对象
     * @param textStatus   {number} 状态码
     * @param errorThrown  {object}  后台返回的数据
     * @description   为了统一管理请求数据报错日志
     */
    requestError(name, data) {
        // 2018-03-21 ，ie会直接弹窗报错，所用 try{}catch(e){}
        try {
            console.log(name);
            console.error('请求数据发生了错误');
            console.error(data);
        } catch (e) {
            // console.log(e)
        }
    },

    /**
     *
     * @name   consoleLog
     * @time 2018-03-21
     * @param showLog   {boolean}   是否输出日志
     * @param logInfo    {null}    要输出的日志信息
     * @description  简化输出日志的方法
     */
    consoleLog(showLog, logInfo) {
        if (showLog) {
            console.log(logInfo);
        }
    },

    /**
     * @name   deepmerge
     * @time   2018-05-28
     * @param  target  {object}    合并后的对象
     * @param  source  ｛object｝   需要合并的对象
     * @param  options ｛object｝   参数
     * @returns {*}
     * @description  深度合并对象
     详情参考：https://github.com/KyleAMathews/deepmerge/blob/master/index.js
     */
    deepmerge: function (target, source, options) {
        // 如果
        if (!source) {
            return null;
        }
        // 默认参数。判断是否是可合并对象的方法
        const defaultIsMergeableObject = this.isMergeableObject();

        // 清空对象
        function emptyTarget(val) {
            // 如果是数组，那么就是 [] ||  {}
            return Array.isArray(val) ? [] : {};
        }

        //  除非另有规定，否则克隆
        function cloneUnlessOtherwiseSpecified(value, options) {
            return (options.clone !== false && options.isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, options) : value;
        }

        // 合并数组的方法
        function defaultArrayMerge(target, source, options) {
            return target.concat(source).map(function (element) {
                //  除非另有规定，否则克隆
                return cloneUnlessOtherwiseSpecified(element, options);
            });
        }

        // 合并对象的方法
        function mergeObject(target, source, options) {
            // 目标
            const destination = {};
            // 判断对象是否可以合并
            if (options.isMergeableObject(target)) {
                // d循环对象
                Object.keys(target).forEach(function (key) {
                    destination[key] = cloneUnlessOtherwiseSpecified(target[key], options);
                });
            }

            Object.keys(source).forEach(function (key) {
                if (!options.isMergeableObject(source[key]) || !(target && target[key])) {
                    destination[key] = cloneUnlessOtherwiseSpecified(source[key], options);
                } else {
                    destination[key] = deepmerge(target[key], source[key], options);
                }
            });
            return destination;
        }

        //  深度合并
        function deepmerge(target, source, _options) {
            let options = _options;
            options = options || {};
            //  数组合并的方法
            options.arrayMerge = options.arrayMerge || defaultArrayMerge;
            // 是否是可合并的对象
            options.isMergeableObject = options.isMergeableObject || defaultIsMergeableObject;
            // 要合并的对象是否是数组
            const sourceIsArray = Array.isArray(source);
            // 目标对象树是数组
            const targetIsArray = Array.isArray(target);
            // 判断目标对象和要合并的对象是否是同一类型
            const sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;
            // 如果不是同类型。
            if (!sourceAndTargetTypesMatch) {
                //  除非另有规定，否则克隆
                return cloneUnlessOtherwiseSpecified(source, options);
            } else if (sourceIsArray) {
                // 合并数组
                return options.arrayMerge(target, source, options);
            }
            // 合并对象
            return mergeObject(target, source, options);
        }

        // 函数本身就是对象,函数一等公民，可以任意添加属性
        this.deepmerge.all = function deepmergeAll(array, options) {
            if (!Array.isArray(array)) {
                throw new Error('first argument should be an array');
            }

            return array.reduce(function (prev, next) {
                return deepmerge(prev, next, options);
            }, {});
        };
        // 自动执行
        return deepmerge(target, source, options);
    },

    /**
     *
     * @name   merge
     * @time   2018-05-28
     * @returns  traget        {object}   合并后的对象
     * @description      对象浅合并
     */
    merge(target) {
        for (let i = 1, j = arguments.length; i < j; i++) {
            const source = arguments[i] || {};
            for (const prop in source) {
                //  判断自身属性，如果有才添加，不判断原型
                if (source.hasOwnProperty(prop)) {
                    const value = source[prop];
                    // eslint-disable-next-line no-undefined
                    if (value !== undefined) {
                        target[prop] = value;
                    }
                }
            }
        }
        return target;
    },


    /**
     *
     * @name   isEmptyArray
     * @time   2018-05-28
     * @param   val     {Array}     需要判断的值
     * @returns  ｛boolean｝        {boolean}
     * @description      判断是否是空数
     */
    isEmptyArray(val) {
        return val.reduce((isEmpty, str) => (isEmpty && !str) || (typeof str === 'string' && str.trim() === ''), true);
    },


    /**
     *
     * @name   getElementRect
     * @time   2018-06-01
     * @param  element  {object}  目标对象
     * @returns  ｛top:number,left:number，width:number,height:number,right:number,bottom:number｝      {object}
     * @description      当前状态元素距离屏幕的位置
     */
    getElementRect(element) {
        const rect = element.getBoundingClientRect();
        return rect;
    },
    // 判断是ie
    isIe() {
        return Boolean(window.ActiveXObject) || 'ActiveXObject' in window;
    },

    /**
     * @name preventDefault
     * @param  event  {object}  event对象
     * @description 阻止默认事件
     */
    preventDefault(event) {
        const _event = event || window.event;
        if (_event.preventDefault) {
            // 如果是非ie下执行这个
            _event.preventDefault();
        } else {
            // 如果是IE下执行这个
            _event.returnValue = false;
        }
    },

    /**
     * @name stopPropagation
     * @param  event  {object}  event对象
     * @description 阻止事件冒泡
     */
    stopPropagation(event) {
        const _event = event || window.event;
        if (_event.stopPropagation) {
            _event.stopPropagation();
        } else {
            _event.cancelBubble = true;
        }
    },


    /**
     * @updateTime   2018-11-02
     * @param       _size { number }
     * @returns       size {string}
     * @description  获取文件大小的方法
     */
    formatFileSize: function (_size) {
        let size = _size;
        if (size > 1024 * 1024) {
            size = `${(Math.round(size * 100 / (1024 * 1024)) / 100).toString()}MB`;
        } else if (size > 1024) {
            size = `${(Math.round(size * 100 / 1024) / 100).toString()}KB`;
        } else {
            size = `${size}B`;
        }
        return size;
    },


    /**
     *
     * @name   getIsChrome
     * @time   2018-11-29
     * @description      判断是否是chrome浏览器
     */
    getIsChrome() {
        if (Boolean(window.ActiveXObject) || 'ActiveXObject' in window) {
            return false;
        }
        return true;
    },
    // 获取IE版本
    IEVersion() {
        // 取得浏览器的userAgent字符串
        const userAgent = navigator.userAgent;
        // 判断是否IE<11浏览器
        const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
        const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
        if (isIE) {
            return true;
        } else if (isIE11) {
            return false;
        }
    },
    // 判断32为还是64位系统
    computerDigit() {
        const agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
            return '32';
        }
        if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
            return '64';
        }
    },
    // 正在加载
    globalLoading(flag) {
        if (flag) {
            this.addClass(this.body, 'fd-body-loading');
        } else {
            this.removeClass(this.body, 'fd-body-loading');
        }
    },
    // 获取开始时间
    getStartTime() {
        return new Date().getTime();
    },
    // 打印用时
    printUseTime(startTime, message) {
        console.log(`${message}用时:${new Date().getTime() - startTime}`);
    },
    // 获取用时信息
    getUseTimeMessage(startTime, message) {
        return `${message}用时:${new Date().getTime() - startTime}`;
    },
    // 获取url参数
    getUrlParams() {
        const params = {};
        const paramsArr = window.location.hash.substr(14).split('&');
        paramsArr.forEach(item => {
            const arr = item.split('=');
            params[arr[0]] = arr[1];
        });
        return params;
    },
    // 获取随机数
    getRandomForUUID() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    // 获取32位随机数
    getUUID() {
        return (this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID() +
            this.getRandomForUUID());
    },
    // 数组去重
    arrDuple(arr) {
        if (arr.length < 2) {
            return arr;
        }
        const set = new Set(arr);
        return [...set];
    },
    // 数字转时间格式
    timestampToTime(timestamp) {
        // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var date = new Date(timestamp);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + D + h + m + s;
    },
    // 表单检验方法
    validateHandler(refName, callBcfn, instance) {
        // 表单校验
        instance.$refs[refName].validate((valid) => {
            if (valid) {
                callBcfn && callBcfn();
            } else {
                return false;
            }
        });
    },
    // 对数据进行分割，结果为10条一个数组的大集合
    sliceArrFn(array, number) {
        var result = [];
        var size = number;  //自定义数组的长度
        for (var x = 0; x < Math.ceil(array.length / size); x++) {
            var start = x * size;
            var end = start + size;
            result.push(array.slice(start, end));
        }
        return result;
    },
    /**
     *  时间日期格式化
     * @param fmt 需要转变的形式
     * @param date 传入的时间
     * @returns {*}
     */
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    },
    /**
     * vue限制输入框只能输入数字和一个小数点，并可保留小数点后任意位
     * @param  str 当前输入值
     * @param  num 是想要保留的小数位数
     * @returns
     */
    formatDecimal(str, num) {
        str = String(str)
        if (!str.trim()) {
          return str
        }
        var len1 = str.substr(0, 1)
        var len2 = str.substr(1, 1)
        // 如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 === 0 && len2 !== '.') {
          str = str.substr(1, 1)
        }
        // 第一位不能是.
        if (len1 === '.') {
          str = ''
        }
        // 限制只能输入一个小数点
        if (str.indexOf('.') !== -1) {
          var str_ = str.substr(str.indexOf('.') + 1)
          if (str_.indexOf('.') !== -1) {
            str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
          }
        }
        // 正则替换
        str = str.replace(/[^\d^.]+/g, '').replace(/^0+(\d)/, "$1") // 保留数字和小数点
        if (num || num === 0) {
          var rest = str.split('.')[1]
          if (rest && rest.length > num) {
            rest = rest.substr(0, num)
            str = str.split('.')[0] + '.' + rest
          }
        }
        return str
      },
    // 使用 window.global.red()打印
    red(str) {
        console.log(`%c${str}`, 'color: red')
    },
    // 获取路由信息
    getRouter(rout, tier1, tier2, tier3) {
        const _data = {
            tier1: null,
            tier2: null,
            tier3: null
        };

        _data.tier1 = rout.find(item => item.key === tier1) || null;
        _data.tier2 = (_data.tier1 && _data.tier1.children.find(item => item.key === tier2)) || null;
        _data.tier3 = (_data.tier2 && _data.tier2.children.find(item => item.key === tier3)) || null;

        return _data;
    },
    //
    getTreeIndex(tier3, commonUserInfoTree) {
        // Tab目录
        let indexC_Tab;
        // 左侧树1级
        let indexC_1;
        // 左侧树2级
        let indexC_2;
        // 二级对象
        let secondData = null;
        // 针对上面数据进行遍历查找
        window.fdGlobal.everyFindIndexFn(commonUserInfoTree, tier3.tab.key, (index) => {
            indexC_Tab = index;
        });
        window.fdGlobal.everyFindIndexFn(tier3.tab.children, tier3.parentKey, (index, itemc) => {
            indexC_1 = index;
            secondData = itemc;
        });
        window.fdGlobal.everyFindIndexFn(secondData.children, tier3.key, (index) => {
            indexC_2 = index;
        });
        tier3.source = [indexC_1, indexC_2];
    },
    // 跳转指定的根页面
    jumpViewByLeafKey(userInfoTree, t3, fn){
        try {
            const t2 = this.findTireData(userInfoTree, t3).parentKey;
            const t1 = this.findTireData(userInfoTree, t2).parentKey;
            const data = this.getRouter(userInfoTree, t1, t2, t3);
            const findIndexByKey = userInfoTree.map(item => item.key).indexOf(t1)
            fdEventBus.$emit('navigationChangeTabHandler', null, findIndexByKey, data.tier3);
            fdEventBus.$emit('changeLeftTabHandler', data.tier2, data.tier3, t1);
            window.fdGlobal.getTreeIndex(data.tier3, userInfoTree);
            fdEventBus.$emit('openNavTree', data.tier3);
            fn && fn();
        }
        catch (e){
            console.log(e)
            throw e;
        }
    },
    findTireData(userInfoTree, t1) {
        for (let item of userInfoTree) {
            if (item.key === t1) {
                return item;
            }
            if (item.children) {
                let found = this.findTireData(item.children, t1);
                if (found !== undefined) {
                    return found; // 如果在子树中找到了匹配的项，立即返回
                }
            }
        }
        return undefined; // 如果没有找到匹配的项，返回undefined
    },

    // 循环查找index
    everyFindIndexFn(list, itemkey, fn) {
        list.every((itemc, index) => {
            if (itemc.key === itemkey) {
                fn(index, itemc);
                return false;
            }
            return true;
        });
    },
    assignProperties(src, dest) {
        // 检查源对象和目标对象是否有效
        if (!src || !dest) return;

        // 遍历源对象的每个属性
        for (let key in src) {
            if (dest.hasOwnProperty(key)) {
                // 赋值给目标对象，这里假设属性值可以直接赋值
                // 如果需要特殊处理（如类型转换），可以在此处添加逻辑
                dest[key] = src[key];
            }
        }
    }
};

const module = new FdGlobal();
//  注册全局变量，FdGlobal
window.fdGlobal = module;
