import {Loading} from "element-ui";
import axios from "axios";
import router from "@/router";
import Vue from 'vue';

let loading;
let loadingNum=0;
function startLoading() {
    if(loadingNum==0){
        loading = Loading.service({
            lock: true,
            text: '拼命加载中...',
            background:'rgba(255,255,255,0.5)',
        })
    }
    //请求数量加1
    loadingNum++;
}
function endLoading() {
    //请求数量减1
    loadingNum--
    if(loadingNum<=0){
        loading && loading.close()
    }
}

//请求数据拦截器
axios.interceptors.request.use(request => {
    // 如果不需要等待效果先将loading数字++，为了让后面endLoading结束--数字准确
    if (request.notShowAwait){
        loadingNum++
    }
    !request.notShowAwait && startLoading();
    return request;
}, err => {
    return Promise.reject(err);
});


//接收响应拦截器
axios.interceptors.response.use(response => {
    // token 过期需要重新登录
    if (['A99999','A99991'].includes(response.data.code)){
        // const newVue = new Vue();
        // newVue.$message({
        //     type: 'warning',
        //     message: '',
        //     duration: 2000,
        //     showClose : true,
        // });
        // 失效后要清楚token，登录后会重新赋值
        localStorage.removeItem('token');
        router.push('/login');
        endLoading();
        return Promise.reject({
            msg:'token无效，要重新登录!'
        })
        // success为false表示接口业务返回失败，但服务连接没问题
    }else if(response.data.hasOwnProperty('success') && !response.data.success) {
        endLoading();
        return Promise.reject(response.data);
        // 正常返回
    }else {
        endLoading();
        return response
    }
}, err => {
    endLoading();
    if (err && err.response) {
        switch (err.response.status) {
            case 400: err.msg = '请求错误(400)'; break;
            case 401: {
                router.push('/login');
                break;
            }
            case 403: err.msg = '拒绝访问'; break;
            case 404: err.msg = '请求出错(404)'; break;
            case 408: err.msg = '请求超时(408)'; break;
            case 500: err.msg = '服务器错误(500)'; break;
            case 501: err.msg = '服务未实现(501)'; break;
            case 502: err.msg = '网络错误(502)'; break;
            case 503: err.msg = '服务不可用(503)'; break;
            case 504: err.msg = '网络超时(504)'; break;
            case 505: err.msg = 'HTTP版本不受支持(505)'; break;
            default: err.msg = `连接出错!`;
        }
    } else {
        console.log(err)
        err.msg = '连接服务器失败!'
    }
    return Promise.reject(err.response.status === 401? {msg: 'token无效，要重新登录!'} : err);
});
